import React, { FC } from "react";
import { myData } from "../../Data/Data";
import s from "./Links.module.css";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ILinks } from "../../Data/iData";

export const Links: FC = () => {
  const renderTooltip = (tooltip: ILinks) => (
    <Tooltip id={`tooltip-bottom`}>
      <strong>{tooltip.name}</strong>
    </Tooltip>
  );

  return (
    <React.Fragment>
      <div className={s.iconsLink}>
        {myData.links.map((link, key) => {
          return (
            <a
              key={key}
              target="_blank"
              rel="noreferrer nopener"
              href={link.url}
            >
              <OverlayTrigger
                placement="bottom"
                overlay={renderTooltip(link)}
                defaultShow={false}
              >
                <i className={link.icon} />
              </OverlayTrigger>
            </a>
          );
        })}
      </div>
    </React.Fragment>
  );
};
