import React, { FC } from "react";
import s from "./Intro.module.css";
import { useTranslation } from "react-i18next";

export const Intro: FC = () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className={s.intro}>{t("title")}</div>
      <div className={s.tagline}>{t("tagline")}</div>
      <div className={s.subtagline}>{t("subtagline")}</div>
    </React.Fragment>
  );
};
