import { IData } from "./iData";

export const myData: IData = {
  backgroundVideoUrl:
    "https://res.cloudinary.com/listratenkov/video/upload/v1605452564/listratenkov.com/typing.mp4",
  links: [
    {
      name: "GitHub",
      url: "https://github.com/flags8192",
      icon: "fab fa-github",
    },
    {
      name: "Twitter",
      url: "https://twitter.com/flags8192",
      icon: "fab fa-twitter",
    },
    {
      name: "devTo",
      url: "https://dev.to/flags8192",
      icon: "fab fa-dev",
    },
    {
      name: "Stack Overflow",
      url: "https://stackoverflow.com/story/flags8192",
      icon: "fab fa-stack-overflow",
    },
    {
      name: "LinkedIn",
      url: "https://www.linkedin.com/in/listratenkov/",
      icon: "fab fa-linkedin",
    },
    {
      name: "Medium",
      url: "https://medium.com/@a.listratenkov",
      icon: "fab fa-medium",
    },
    {
      name: "freeCodeCamp",
      url: "https://www.freecodecamp.org/listratenkov",
      icon: "fab fa-free-code-camp",
    },
    {
      name: "Мой блог",
      url: "https://blog.listratenkov.com",
      icon: "fab fa-wordpress",
    },
    {
      name: "CodePen",
      url: "https://codepen.io/flags8192",
      icon: "fab fa-codepen",
    },
    {
      name: "E-mail",
      url: "mailto:mail@listratenkov.com",
      icon: "fas fa-at",
    },
    {
      name: "Резюме",
      url: "/cv/resume.pdf",
      icon: "fa fa-file-pdf",
    },
  ],
};
