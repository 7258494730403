import React, { FC } from "react";
import { Background } from "../Background/Background";
import { Content } from "../Content/Content";

export const Main: FC = () => {
  return (
    <React.Fragment>
      <Background />
      <Content />
    </React.Fragment>
  );
};
