import React, { FC } from "react";
import { Intro } from "../Intro/Intro";
import { Links } from "../Links/Links";
import { LanguageSwitcher } from "../LanguageSwitcher/LanguageSwitcher";

export const Content: FC = () => {
  return (
    <main id="main">
      <LanguageSwitcher />
      <Intro />
      <Links />
    </main>
  );
};
