import React, { FC } from "react";
import i18n from "i18next";
import s from "./LanguageSwitcher.module.css";

export const LanguageSwitcher: FC = () => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      void i18n.changeLanguage("ru");
    } else {
      void i18n.changeLanguage("en");
    }
  };

  return (
    <div className={s.language_switch}>
      <label className={s.language_switch__wrapper}>
        <input type="checkbox" name="languages" onChange={handleChange} />
        <span className={s.language_switch__slider}></span>
        <div></div>
      </label>
    </div>
  );
};
