import React, { FC } from "react";
import { myData } from "../../Data/Data";

export const Background: FC = () => {
  return (
    <video autoPlay muted loop id="video">
      <source src={myData.backgroundVideoUrl} type="video/mp4" />
    </video>
  );
};
