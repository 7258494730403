import "bootstrap/dist/css/bootstrap.min.css";
import React, { FC } from "react";
import "./App.css";
import { YMInitializer } from "react-yandex-metrika";
import { Main } from "./Components/Main/Main";

export const App: FC = () => {
  return (
    <React.Fragment>
      <Main />
      <YMInitializer
        accounts={[11677270]}
        options={{
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
        }}
      />
    </React.Fragment>
  );
};
